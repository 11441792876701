import { Toast } from 'vant';
export const phoneValidateFn = (phone, callback = new Function()) => {
    let reg = /^[1][3-9]\d{9}$/;
    if (phone) {
        if (reg.test(phone)) {
            callback();
        } else {
            Toast('手机号格式不正确');
        }
    } else {
        Toast('手机号不能为空');
    }
}