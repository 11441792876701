<template>
  <div class="drawpackage">
    <navbarTools
      :leftArrow="true"
      :fixed="true"
      :rightText="rightText"
      :rightFn="rightFn"
    ></navbarTools>
    <div class="packageInfoBox">
      <img :src="OSS_URL + '/welfare/p_bg.png'" class="p_bg" />
      <div class="packageInfo">
        <div class="packageInfo_box_bg">
          <div class="package_content">
            <div class="title">填写领取礼包的账号</div>
            <div>
              <img :src="OSS_URL + '/welfare/elm_p1.png'" />
              <img :src="OSS_URL + '/welfare/mg_p1.png'" />
              <img :src="OSS_URL + '/welfare/jd_p1.png'" />
              <img :src="OSS_URL + '/welfare/mt_p1.png'" />
            </div>
          </div>
          <div class="package_input">
            <van-field
              v-model="phone"
              placeholder="请输入领取手机号"
              maxlength="11"
            />
            <van-field
              v-model="confirmPhone"
              placeholder="请再次输入领取手机号"
              maxlength="11"
            />
            <van-button color="#FC7501" class="confirm_draw" @click="drawFn"
              >确认</van-button
            >
            <div class="remark">确认后不支持修改</div>
          </div>
        </div>
        <div class="rules">
          <div class="title fs16 c-fff">活动规则</div>
          <div class="fs14 c-fff">
            1.活动时间：2022年1月7日-2022年12月31日。
          </div>
          <div class="fs14 c-fff">
            2.活动对象：2022年1月7日之后，在熟人直聘注册登记并正在任职于自营岗位的会员。
          </div>
          <div class="fs14 c-fff">
            3.领取规则：活动期间每位具有参与资格的会员，仅限领取一次新手礼包。
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="drawShow"
      class="drawPopup"
      :close-on-click-overlay="false"
    >
      <div class="drawBox">
        <div class="title">领取成功</div>
        <div class="content">
          您已成功领取了入职礼包，满勤7天最低可领价值80元工钱币，届时记得前往福利中心领取哦～
        </div>
        <div class="know">
          <van-button color="#FC7501" @click="successFn">我知道了</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent,getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import navbarTools from "@/components/navbarTools";
import { phoneValidateFn } from "@/utils/rules";
import { recevieFixedGiftBoxSkuEqutiy } from "@/api/equity";
import { Toast } from "vant";
import { ua } from "@/utils/ua";
export default defineComponent({
  components: {
    navbarTools,
  },
  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const app = getCurrentInstance();
    const state = reactive({
      count: 0,
      rightText: "使用规则",
      OSS_URL: process.env.VUE_APP_OSS_URL,
      phone: null,
      drawShow: false,
      confirmPhone: null,
      ifClick: true,
    });
    const successFn = () => {
      state.drawShow = false;
      if (ua.isAndroidApp || ua.isIosApp) {
        app.proxy.$bridge.handleMethods("goBack");
      } else {
        $router.go(-1);
      }
    };
    const drawFn = () => {
      phoneValidateFn(state.phone, async () => {
        if (state.confirmPhone !== state.phone) {
          Toast("输入手机号码不一致");
        } else {
          if (state.ifClick) {
            state.ifClick = false;
            setTimeout(() => {
              state.ifClick = true;
            }, 1500);
            let rs = await recevieFixedGiftBoxSkuEqutiy({
              equityBatchId: $route.query.equityBatchId,
              orderRemark: state.phone,
              limitStartTime: $route.query.limitStartTime,
              limitEndTime: $route.query.limitEndTime,
            });
            if (rs.code === 0) {
              state.drawShow = true;
            }
          }
        }
      });
    };
    const rightFn = () => {
      if (!state.drawShow) {
        $router.push({
          path: "/welfarecenter/packagerules",
        });
      }
    };
    return {
      rightFn,
      successFn,
      drawFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.drawpackage {
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }

  .packageInfoBox {
    position: relative;
    top: 46px;
    height: 100%;
    background: #fc7501;
    .p_bg {
      width: 375px;
      height: 276px;
    }
    .packageInfo {
      width: 375px;

      position: relative;
      top: -3px;
      padding-bottom: 10px;
      .packageInfo_box_bg {
        width: 355px;
        height: 279px;
        background: #fff;
        border-radius: 6px;
        position: relative;
        left: 10px;
        .package_content {
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
          .title {
            font-weight: 600;
            font-size: 16px;
            color: #333;
          }
          img {
            width: 24px;
            height: 26px;
            padding: 0 5px;
          }
        }
        .package_input {
          .confirm_draw {
            width: 315px;
            height: 42px;
            margin-top: 50px;
            margin-left: 20px;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 600;
          }
          .remark {
            margin: 15px 0px 0px 20px;
            font-size: 12px;
            color: #fc7501;
          }
        }
      }
      .rules {
        height: auto;
        padding: 15px;
        line-height: 28px;
        .title {
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
  ::v-deep(.drawPopup) {
    border-radius: 8px;
    .drawBox {
      width: 286px;
      height: 216px;
      background: #ffffff;
      padding: 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
      .content {
        font-size: 14px;
        color: #333;
        margin-top: 15px;
        line-height: 30px;
      }
      .know {
        margin-top: 10px;
        .van-button {
          width: 251px;
          height: 42px;
          border-radius: 21px;
        }
      }
    }
  }
}
</style>
